import React from 'react'
import PropTypes from 'prop-types'
import './layout.css'

const Layout = ({ children }) => (
    <>
        <div
            style={{
                cursor: 'none',
                margin: `0 auto`,
            }}>
            <main>{children}</main>
        </div>
    </>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
